<template>

    <iframe id="sceneFrame" :src="iframeUrl"></iframe>
    <div>
        <br />
        <hr />
        <br />
    </div>
    <div v-if="tournaments" class="content">

        <div>
            <div>
                <br />
            </div>
            <select class="select-list" v-model="selectedCompId" @change="competitionChange">
                <option v-for="compId in compIds" :value="compId" :key="compId">{{compId}}</option>
            </select>
            <div>
                <br />
            </div>
            <button class="sceneBtn" @click="sendWelcomeScene">Send <b>WELCOME</b> Scene</button>
        </div>


        <div>
            <br /><hr />
        </div>

        <div>
            <div>
                <br />
            </div>
            <select class="select-list" v-model="selectedScheduleDate" @change="changeScheduleDate">
                <option v-for="scheduleDate in scheduleDates" :value="scheduleDate.date" :key="scheduleDate.date">{{scheduleDate.dateTitle}}</option>
            </select>
            <div>
                <br />
            </div>
            <button class="sceneBtn" @click="sendScheduleScene">Send <b>SCHEDULES</b> Scene</button>

        </div>

        <div>
            <br /><hr />
        </div>

        <button class="scheduleBtn" @click="sortByScheduledStartTime">Sort By Scheduled Start Time</button>

        <div>
            <br />
        </div>

        <button class="scheduleBtn" @click="worldAthleticsBtn">Sort By World Athletics</button>

        <div>
            <br />
        </div>

        <button class="scheduleBtn" @click="refreshUnits">Refresh List</button>

        <div>
            <br />
        </div>

        <table>
            <thead>
                <tr>
                    <th>Order</th>
                    <th>Tournament</th>
                    <th>Scheduled Start Time</th>
                    <th>Change Order</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="tournament in tournaments" :key="tournament.id">
                    <td> {{ tournament.order }} </td>
                    <td> <b>{{ tournament.tournamentNode.names.FULL.name }} </b> </td>
                    <td> {{ tournament.tournamentNode.scheduledStartTime }} </td>
                    <td>
                        <button class="sendStartlistBtn" @click="showStartList(tournament.tournamentNode.id)"> Send Start List </button>
                        <button class="upDownBtn" @click="moveUp(tournament.tournamentNode.id)"> &uarr; Up</button>
                        <button class="upDownBtn" @click="moveDown(tournament.tournamentNode.id)"> &darr; Down</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    import axios from 'axios';
    import { ContextHolder } from "@frontegg/vue";
    import { config } from '../../configurations/current/config.js';
    export default {
        name: 'OrderSchedules',
        mounted() {
            this.getCurrentCompetition();
            this.getAllCompetitions();
        },
        data() {
            return {
                iframeUrl: config.sceneUrl,
                baseApiUrl: config.apiUrl,
                st: "",
                st2: [],
                tournaments: [],
                scheduleDates: [],
                selectedScheduleDate: null,
                compIds: [],
                selectedCompId: null,
                ...this.mapAuthState(),
            }
        },
        methods: {

            logout() {
                const baseUrl = ContextHolder.getContext().baseUrl;
                window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
            },

            getCurrentCompetition() {
                axios.get(this.baseApiUrl + 'schedules/CurrentCompetition', {
                    headers: {
                        Authorization: "Bearer " + this.authState.user.accessToken
                    }
                })
                    .then((response) => {
                        this.selectedCompId = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            getTournaments(compId) {
                axios.post(this.baseApiUrl + 'schedules/GetAllUnitSchedules', compId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.tournaments = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }

                    })
            },

            sortByScheduledStartTime() {
                axios.get(this.baseApiUrl + 'schedules/SortByScheduledStartTime', { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.tournaments = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            moveUp(tournamentId) {
                axios.post(this.baseApiUrl + 'schedules/TournamentOrderMoveUp', tournamentId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.tournaments = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            moveDown(tournamentId) {
                axios.post(this.baseApiUrl + 'schedules/TournamentOrderMoveDown', tournamentId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.tournaments = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            worldAthleticsBtn() {
                axios.post(this.baseApiUrl + 'schedules/OrderByWorldAthletics', this.selectedCompId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.tournaments = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }

                    })
            },

            sendWelcomeScene() {
                axios.post(this.baseApiUrl + 'Scene/Welcome', this.selectedCompId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            sendScheduleScene() {
                let data = {
                    compId: this.selectedCompId,
                    day: this.selectedScheduleDate
                }
                axios.post(this.baseApiUrl + 'Scene/Schedule', data, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            showStartList(tId) {
                const requestBody = {
                    compId: this.selectedCompId,
                    tournamentId: tId
                };
                axios.post(this.baseApiUrl + 'Scene/Startlist', requestBody, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            refreshUnits() {
                axios.post(this.baseApiUrl + 'schedules/RefreshSchedules', this.selectedCompId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.tournaments = response.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            getAllCompetitions() {
                axios.get(this.baseApiUrl + 'schedules/GetAllCompetitions', { headers: { Authorization: "bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } }).then((response) => {
                    this.compIds = response.data;
                })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }
                    })
            },

            getAllScheduleDates(compId) {
                axios.post(this.baseApiUrl + 'schedules/GetAllScheduleDates', compId, { headers: { Authorization: "Bearer " + this.authState.user.accessToken, 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.scheduleDates = response.data;
                        this.selectedScheduleDate = this.scheduleDates[0].date;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            this.logout();
                        }

                    })
            },

            competitionChange(event) {
                this.selectedCompId = event.target.value;
                this.getTournaments();
            },

            changeScheduleDate(day) {
                this.selectedScheduleDate = day.target.value;
            }

        },
        watch: {
            selectedCompId(newCompId, oldCompId) {
                if (newCompId !== oldCompId && newCompId !== "") {
                    this.getTournaments(newCompId);
                    this.getAllScheduleDates(newCompId);
                }
            }
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    .button-row {
        display: flex;
        justify-content: space-between
    }

    #sceneFrame {
        padding: 1px;
        width: 90%;
        height: 35em;
        border: 5px solid;
        box-shadow: 2px 2px 20px 0px #134ae5;
        border-radius: 25px;
    }

    .upDownBtn {
        margin-left: 10px;
        width: 70px;
        background-color: #ffeb00;
        height: 35px;
        border: 0.5px solid lightgray;
        border-radius: 15px;
    }

    .sendStartlistBtn {
        color: white;
        background-color: #288ddb;
        margin-left: 10px;
        height: 35px;
        border: 0.5px solid lightgray;
        border-radius: 15px;
    }

        .sendStartlistBtn:hover {
            box-shadow: 2px 2px 5px 0px #8dbe16;
            cursor: pointer;
            opacity: 100% !important;
        }

    .upDownBtn:hover {
        box-shadow: 2px 2px 10px 0px #134ae5;
        cursor: pointer;
        opacity: 100% !important;
    }

    .scheduleBtn:hover {
        box-shadow: 2px 2px 5px 0px #8dbe16;
        cursor: pointer;
        opacity: 100% !important;
    }

    .sceneBtn:hover {
        box-shadow: 2px 2px 5px 0px #8dbe16;
        cursor: pointer;
        opacity: 100% !important;
    }

    .content {
        padding: 0px 250px;
    }

    .sceneBtn {
        padding: 17px;
        border-radius: 30px;
        color: white;
        background-color: #288ddb;
        border: none;
        box-shadow: 2px 2px 10px 0px #288ddb;
        width: 250px;
    }

    .scheduleBtn {
        padding: 17px;
        border-radius: 30px;
        color: white;
        background-color: #254d54;
        border: none;
        width: 250px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        padding: 14px;
        border: 1px solid lightgray;
    }

    tr:hover {
        box-shadow: 2px 2px 10px 0px #f8da01;
    }

    th {
        background-color: #254d54;
        color: white;
    }

    .select-list {
        width: 17em;
        height: 2.5em;
        border-radius: 5px;
        box-shadow: 2px 2px 20px 0px #134ae5;
    }

        .select-list option {
            margin: 5px;
        }
</style>