import { createApp } from "vue";
import App from "./App.vue";
import { Frontegg } from "@frontegg/vue";
import { createRouter, createWebHistory } from "vue-router";
import { config } from '../configurations/current/config.js';

const router = createRouter({
    history: createWebHistory("/"),
    routes: [
        { name: "HomePage", path: "/", component: App }
    ],
});

const app = createApp(App).use(router);

app.use(Frontegg, {
    contextOptions: {
        baseUrl: config.frontegg.baseUrl,
        clientId: config.frontegg.clientId
    },
    authOptions: {
        // keepSessionAlive: true // Uncomment this in order to maintain the session alive
    },
    hostedLoginBox: true,
    router
});

app.mount("#app");