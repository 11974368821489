<script setup>
    import Schedules from "@/components/Schedules.vue";
</script>
<template>
    <div id="app" v-if="fronteggLoaded">
        <div>
            <button v-if="authState.user" v-on:click="logout">Log out</button>
            <br /><br />
        </div>
        <div v-if="authState.user">
            <Schedules />
        </div>

    </div>
</template>

<script>

    import { mapLoginActions, ContextHolder } from "@frontegg/vue";
    import { config } from '../configurations/current/config.js';

    export default {
        name: "App",
        //Available from v1.0.22 for automatic redirect to your app / login box.
        updated() {
            this.loginIfNeeded();
        },
        mounted() {
            this.updateCache();
        },
        methods: {
            // Use either loginWithRedirect or loginIfNeeded.
            loginWithRedirect: mapLoginActions('loginWithRedirect'),
            showAccessToken() {
                alert(this.authState.user.accessToken);
            },
            loginIfNeeded: function () {
                if (!this.$data.authState.isLoading && !this.$data.authState.isAuthenticated) {
                    this.loginWithRedirect();
                }
            },
            logout() {
                const baseUrl = ContextHolder.getContext().baseUrl;
                window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
            },
            switchTenant() {
                // Use this.authState.user.tenantIds to get the tenants the user is a member of
                if (this.authState.user.tenantId !== config.frontegg.tenantId) {

                    this.fronteggAuth.tenantsActions.switchTenant({ tenantId: config.frontegg.tenantId });

                }
            },
            updateCache() {
                var timestamp = new Date().getTime();
                var links = document.getElementsByTagName('link');
                var scripts = document.getElementsByTagName('script');

                for (var i = 0; i < links.length; i++) {
                    if (links[i].rel === 'stylesheet') {
                        links[i].href += '?v=' + timestamp;
                    }
                }

                for (var j = 0; j < scripts.length; j++) {
                    scripts[j].src += '?v=' + timestamp;
                }
            }
        },
        data() {
            return {
                ...this.mapAuthState(),
            }
        },
        watch: {
            'authState.isAuthenticated'() {
                this.switchTenant();
            }
        }
    };
</script>

<style>

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>